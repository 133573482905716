// import {useContext} from 'react'
// import { StateContext } from "../contexts/State";
// const { state, dispatch } = useContext(StateContext);
import moment from "moment";

export function setBgColor(event) {
  if (event.capacity_remaining === 0) {
    return "#c03535";
  } else if (event.color === "blue") {
    return "#4383cb";
  } else if (event.color === "green") {
    return "#0ab818";
  } else if (event.color === "yellow") {
    return "#f0dc00";
  } else if (event.color === "gray") {
    return "#9ea2a3";
  } else if (event.color === "red") {
    return "#c03535";
  }
}

export function capitalizeFirstLetter(inputString) {
  try {
    return `${inputString[0].toUpperCase()}${inputString.slice(1)}`;
  } catch (err) {
    console.error(
      "There was a problem formatting the string:",
      inputString,
      err
    );
  }
}

export function capitalizeAllFirstLetters(inputString) {
  try {
    let words = inputString.trim().split(" ");
    return words
      .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
      .join(" ");
  } catch (err) {
    console.error(
      "There was a problem formatting the string:",
      inputString,
      err
    );
  }
}

export const formatDate = (date) => {
  date = date.toDate();
  const birthday = new Date(date);
  const year = birthday.getFullYear().toString().slice(-2);
  // why is getMonth zero-indexed...? lol
  const month = birthday.getMonth() + 1;
  const day = birthday.getUTCDate();
  return date ? `${month}/${day}/${year}` : "N/A";
};

export const formatDateTime = (date) => {
  date = date.toDate();
  return date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const trimText = (text) => {
  if (text.length > 20) {
    return `${text.slice(0, 20)}...`;
  } else return text;
};

export const getFirstAndLastDayOfWeek = () => {
  let wDate = new Date();
  let dDay = wDate.getDay() > 0 ? wDate.getDay() : 7;
  let first = wDate.getDate() - dDay;
  let firstDayWeek = new Date(wDate.setDate(first));
  let lastDayWeek = new Date(wDate.setDate(firstDayWeek.getDate() + 6));
  const start = firstDayWeek.toLocaleDateString().split("/");
  const end = lastDayWeek.toLocaleDateString().split("/");
  return [
    {
      month: start[0],
      day: start[1],
      year: start[2],
      dayOfTheWeek: firstDayWeek.getDay(),
    },
    {
      month: end[0],
      day: end[1],
      year: end[2],
      dayOfTheWeek: lastDayWeek.getDay(),
    },
  ];
};

export const authRequest = async (request = async () => {}) => {
  // first try the request, sucesss = result
  // fail, refreshToken

  try {
    const data = await request();
    const res = await data.json();
    return res;
  } catch (error) {
    if (error.status === 401) {
      console.error("Access token expired: ", error);
      await refreshToken();
      try {
        const data = await request();
        const res = await data.json();
        return res;
      } catch (error) {
        console.error("access expired: ", error);
        return null;
      }
    } else {
      console.error("Error with request: ", error``);
    }
  }
};

export const refreshToken = async () => {
  // runs refresh endpoints, overrides localStorage
  try {
    const data = await fetch(`${process.env.REACT_APP_API_URL}refresh`, {
      method: "POST",
    });
    const res = await data.json();
    const loggedInUser = localStorage.getItem("currentUser");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      foundUser.token = res;
      localStorage.setItem("currentUser", JSON.stringify(foundUser));
      // dispatch({ type: "STORE_USER_DATA", payload: foundUser });
    }
    return res;
  } catch (error) {
    console.error("Error refreshing token");
  }
};

export const getFormattedTime = (date) => {
  const getTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const day = new Date(date);
  return day
    .toLocaleString("en-US", { timeZone: getTimeZone, hour12: false })
    .split(",")[1];
};

export function isWithinRestrictedTimes(
  restrictedTimes = []
) {
  const temp = new Date().toLocaleString().split(",")[0];
  const isBetweenTimes = moment(new Date()).isBetween(
    new Date(`${temp}, ${restrictedTimes[0]}`),
    new Date(`${temp}, ${restrictedTimes[1]}`)
  );
  return isBetweenTimes;
}
