import {  useState, useEffect } from "react";

const DayHeader = ({ date, label, restrictedDays }) => {
  const today = new Date();
  const [isRestricted, setIsRestricted] = useState(false);
  let currMonth = date.getMonth() === today.getMonth();
  let highlightDate = date.getDate() === today.getDate();

  var dateString = date.toISOString().split("T")[0];
  useEffect(() => {
    if (restrictedDays.includes(dateString)) {
      setIsRestricted(true);
    }
  }, []);
  return (
    <div className="w-full flex">
      <div className={`m-2`}>
        {currMonth ? (
          <p
            className={` relative  top-1
          ${isRestricted ? "rbc-off-range " : ""} ${highlightDate ? "text-[#3E6DB1] font-medium" : ""}`}
          >
            {label}
          </p>
        ) : (
          <p
            className={`text-gray-300 relative  top-1
          ${isRestricted ? "rbc-off-range " : ""}`}
          >
            {label}
          </p>
        )}
      </div>
    </div>
  );
};
export default DayHeader;
