import React, { useEffect, useRef, useState } from "react";
import SearchBar from ".././SearchBar";
import CloseSVG from ".././svg/CloseSVG";
import PlusSVG from ".././svg/PlusSVG";
import PersonSVG from "../svg/PersonSVG";

const SelectAttendees = ({
  students,
  attendees,
  setAttendees,
  setStudents,
  capacity,
  interventionDate,
  loading,
}) => {
  const [filteredStudents, setFilteredStudents] = useState(students);
  const ref = useRef(null);

  useEffect(() => {
    setFilteredStudents(students);
  }, [students]);

  // useEffect(() => [console.log(filteredStudents)], [filteredStudents]);

  function addAttendee(newAttendee, key) {
    if (attendees.length < capacity && capacity > 0) {
      setAttendees((prev) => [
        ...prev,
        {
          ...newAttendee,
          // fields: {
          //   ...newAttendee.fields,
          required: true,
          // },
        },
      ]);
      // setStudents((prev) => {
      //   const tempStudents = [...prev];
      //   tempStudents.splice(key, 1);
      //   return tempStudents;
      // });
    } else return;
  }

  function checkForAttendee(student) {
    if (attendees && attendees.length > 0) {
      if (attendees.find((attendee) => attendee?.id === student?.id)) {
        return true;
      } else return false;
    } else return false;
  }

  function removeAttendee(key) {
    console.log(key);
    setAttendees((prev) => {
      const temp = [...prev];
      temp.splice(key, 1);
      return temp;
    });
  }

  // limit attendees to capacity length
  return (
    <section className="flex w-full justify-evenly shadow-sm gap-2">
      <div className="h-60 overflow-y-scroll p-2 scrollbar flex-1">
        <h3 className="text-2xl mb-2 border-b border-gray-200">
          All Students:
        </h3>
        <SearchBar
          id="attendees-searchbar"
          className="attendees-searchbar mb-2 rounded-md shadow-md"
          placeholder="Search students..."
          searchFields={["first_name", "last_name"]}
          inputArray={students}
          inputRef={ref}
          updateArray={(newArray) => setFilteredStudents([...newArray])}
        />
        {students.length === 0 ? (
          <p className="text-lg my-6 font-medium text-center">
            {" "}
            {interventionDate === "" ? "Please select a date." : "Loading..."}
          </p>
        ) : loading ? (
          <p className="text-lg my-6 font-medium text-center">Loading...</p>
        ) : (
          filteredStudents?.map((student, key) => (
            <div
              key={key}
              className={`flex justify-between p-2 items-center rounded-sm hover:bg-blue-50 ${
                !checkForAttendee(student) && "cursor-pointer"
              }`}
              onClick={() => {
                if (!checkForAttendee(student)) {
                  addAttendee(student, key);
                  if (ref.current.value !== "") {
                    ref.current.value = null;
                    setFilteredStudents(students);
                  }
                }
              }}
            >
              <div className="flex gap-1 items-center">
                {student?.hasOwnProperty("required") && !student?.required && (
                  <PersonSVG className="w-5 text-gray-400" />
                )}
                <p
                  className={`${
                    checkForAttendee(student) ? "text-gray-300" : ""
                  } ${
                    student?.hasOwnProperty("required") ? "text-gray-400" : ""
                  }`}
                >
                  {student.last_name}, {student.first_name}{" "}
                  {student.attendance && `(${student?.attendance})`}
                </p>
              </div>
              {!checkForAttendee(student) ? (
                <PlusSVG className=" text-green-500" />
              ) : (
                <CloseSVG className=" text-gray-300" />
              )}
            </div>
          ))
        )}
      </div>
      <div className="h-60 overflow-y-scroll text-right p-2 scrollbar flex-1">
        <h3 className="text-2xl mb-2 border-b border-gray-200">
          {" "}
          {`${attendees?.length}/${capacity || 0}`} Attendees:
        </h3>
        {attendees
          ?.sort((a, b) =>
            a.last_name.toLowerCase() > b.last_name.toLowerCase() ? 1 : -1
          )
          .map((attendee, key) => (
            <div
              key={key}
              className="flex justify-between p-2 items-center rounded-sm cursor-pointer hover:bg-blue-50"
              onClick={() => removeAttendee(key)}
            >
              <CloseSVG className=" text-red-500" />
              <div className="flex gap-1 justify-between">
                <p className="ml-6">
                  {attendee?.last_name}, {attendee?.first_name}
                </p>
                {!attendee?.required && <PersonSVG className="w-5" />}
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};
export default SelectAttendees;
