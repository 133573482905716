import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import SearchBar from "../SearchBar";
import PersonSVG from "../svg/PersonSVG";

const EventAttendeesList = ({
  currentUser = {},
  event = {},
  teacherEditing = false,
  intervention = {},
  setFilteredAttendees = () => {},
  filteredAttendees = [],
  isOverflowMenu = false,
}) => {
  function isTeacher() {
    if (currentUser?.user?.role === 2 || currentUser?.user?.role === 4) {
      return true;
    } else {
      return false;
    }
  }
  const searchRef = useRef(null);

  if (!isOverflowMenu) {
    return (
      <>
        <div className="flex gap-2 flex-col">
          <div className="flex gap-1 items-end justify-between">
            {
              /* datesAreOnTheSameDay(
        new Date(event.start),
        new Date()
      )  */
              (currentUser?.user?.id === event.owner ||
                (isTeacher() && teacherEditing) ||
                currentUser?.user?.role === 1) && (
                <Link
                  to={"/intervention/" + event.id + "/attendance"}
                  className="bg-blueprimary-500 text-white px-4 py-1.5  rounded-md hover:bg-blueprimary-600"
                >
                  Take attendance
                </Link>
              )
            }
            <SearchBar
              id="event-details-searchbar"
              className="rounded-md attendees-searchbar shadow-sm w-44"
              placeholder="Search..."
              searchFields={["first_name", "last_name"]}
              ref={searchRef}
              inputArray={intervention?.attendees}
              updateArray={(newArray) => setFilteredAttendees([...newArray])}
            />
          </div>
          {searchRef?.current?.value !== "" &&
          filteredAttendees?.length === 0 ? (
            <div className="bg-gray-200 rounded-md shadow-md  px-4 py-2">
              <p className="text-base">
                There are no students that match your search.
              </p>
            </div>
          ) : (
            <div className="bg-gray-200 rounded-md shadow-md  px-4 py-2">
              {filteredAttendees
                ?.sort((a, b) => {
                  if (a.last_name.toLowerCase() > b.last_name.toLowerCase())
                    return 1;
                  if (a.last_name.toLowerCase() < b.last_name.toLowerCase())
                    return -1;
                  return 0;
                })
                .map((attendee, key) => (
                  <div className="flex gap-1" key={key}>
                    <p>
                      {attendee?.last_name}, {attendee?.first_name}
                    </p>
                    {!attendee?.required && <PersonSVG className="w-5" />}
                  </div>
                ))}
            </div>
          )}
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="flex justify-between items-end">
          <span className="text-blueprimary-600">Attendees: </span>
          <div className="flex gap-4 items-end">
            {(currentUser?.user?.id === event.owner ||
              (isTeacher() && teacherEditing) ||
              currentUser?.user?.role === 1) && (
              <Link
                to={"/intervention/" + event.id + "/attendance"}
                className="bg-blueprimary-500 text-white px-4 py-1.5 -mt-3 rounded-md w-fit inline-block "
              >
                Take attendance
              </Link>
            )}
            <SearchBar
              id="event-details-overflow-searchbar"
              className="rounded-md attendees-searchbar shadow-sm"
              placeholder="Search attendees..."
              searchFields={["first_name", "last_name"]}
              inputArray={intervention?.attendees}
              updateArray={(newArray) => setFilteredAttendees([...newArray])}
              ref={searchRef}
            />
          </div>
        </div>
        {searchRef?.current?.value !== "" && filteredAttendees?.length === 0 ? (
          <div className="bg-gray-200 rounded-md shadow-md  px-4 py-2">
            <p className="text-base">
              There are no students that match your search.
            </p>
          </div>
        ) : (
          <div className="flex gap-2 bg-gray-200 rounded-md shadow-md px-4 py-2 w-full flex-wrap">
            {filteredAttendees
              .sort((a, b) => {
                if (a.last_name.toLowerCase() > b.last_name.toLowerCase())
                  return 1;
                if (a.last_name.toLowerCase() < b.last_name.toLowerCase())
                  return -1;
                return 0;
              })
              .map((attendee, key) => (
                <div className="flex gap-1" key={key}>
                  {!attendee?.required && <PersonSVG className="w-5" />}
                  <p className="text-base">
                    {attendee?.last_name}, {attendee?.first_name}{" "}
                    {key !== filteredAttendees.length - 1 ? " - " : ""}
                  </p>
                </div>
              ))}
          </div>
        )}
      </>
    );
  }
};
export default EventAttendeesList;
