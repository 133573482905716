import { useContext, useEffect, useRef, useState } from "react";
import { StateContext } from "../../contexts/State";
import CloseSVG from "../svg/CloseSVG";
import EditSVG from "../svg/EditSVG";
import TrashSVG from "../svg/TrashSVG";
import LockSVG from "../svg/LockSVG";
import CenteredModal from "../CenteredModal";
import EditIntervention from "./EditIntervention";
import ConfirmDeleteEvent from "./ConfirmDeleteEvent";
import { setBgColor } from "../../services/helpers";
import { BarLoader } from "react-spinners";
import moment from "moment";
import RestrictedTimesNotice from "./RestrictedTimesNotice";
import EventAttendeesList from "./EventAttendeesList";
import CopySVG from "../svg/CopySVG";

export default function EventWrapper({
  event,
  children,
  components,
  onSelect,
}) {
  const { state } = useContext(StateContext);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [error, setError] = useState(false);
  const [intervention, setIntervention] = useState({});
  const [filteredAttendees, setFilteredAttendees] = useState([]);
  const [loading, setLoading] = useState(false);
  // isCopiedIntervention is used when a user clicks the "copy" icon
  const [isCopiedIntervention, setIsCopiedIntervention] = useState(false);
  const searchRef = useRef(null);
  const {
    showInterventionModal,
    setCurrMonthInterventions,
    teacherEditing,
    adminDelete,
    restrictedTimes,
    dayOfTheWeek,
    allowMultipleInterventions,
    restrictTeacherAccess,
    setCopiedInterventionDetails,
    setShowInterventionModal,
    setIsCopiedInterventionWrapper,
  } = components;

  const isRestrictedTime =
    isWithinRestrictedTimes(restrictedTimes) &&
    restrictTeacherAccess &&
    (state.currentUser?.user?.role === 2 ||
      state.currentUser?.user?.role === 4);

  useEffect(() => {
    if (intervention?.attendees?.length > 0) {
      setFilteredAttendees(intervention.attendees);
    }
  }, [intervention.attendees]);

  // useEffect(() => {
  //   if (searchRef?.current.value !== "") {

  //   }
  // },[])

  const getIntervention = async (id) => {
    try {
      setLoading(true);
      if (error) {
        setError(false);
      }
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}v2/intervention/${id}`,
        {
          headers: {
            Authorization: "Bearer " + state.currentUser.token,
          },
        }
      );
      const data = await res.json();
      setIntervention(data);
      // setFilteredAttendees(data.attendees);
      setLoading(false);
    } catch (error) {
      console.error(
        "There was an error getting the intervention details: ",
        error
      );
      setError(
        "There was an error getting the intervention details. Please refresh the page and try again. \n"
      );
      setLoading(false);
    }
  };

  async function showPopup() {
    if (!showInterventionModal) {
      onSelect(event);
      setShowModal(!showModal);
      if (Object.keys(intervention).length === 0) {
        await getIntervention(event.id);
      }
    } else return;
  }

  function isWithinRestrictedTimes() {
    const temp = new Date().toLocaleString().split(",")[0];
    const isBetweenTimes = moment(new Date()).isBetween(
      new Date(`${temp}, ${restrictedTimes[0]}`),
      new Date(`${temp}, ${restrictedTimes[1]}`)
    );
    return isBetweenTimes;
  }

  async function deleteSubsequentInterventions() {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}intervention/delete/repeating/${event?.intervention_series_id}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + state.currentUser.token,
          },
          body: JSON.stringify({
            date: event?.date,
          }),
        }
      );
      const data = await res.json();
      await setCurrMonthInterventions((prev) => {
        const temp = { ...prev };
        const tempAllInterventions = temp.all_interventions;
        for (const interventionId of data?.deleted_ints) {
          tempAllInterventions.splice(
            temp.all_interventions.findIndex(
              (intervention) => intervention.id === interventionId
            ),
            1
          );
        }
        if (state.currentUser?.user?.role === 2) {
          const tempMyInterventions = temp.my_interventions;
          for (const interventionId of data?.deleted_ints) {
            tempMyInterventions.splice(
              temp.my_interventions.findIndex(
                (intervention) => intervention.id === interventionId
              ),
              1
            );
          }
          return {
            ...prev,
            my_interventions: [...prev.my_interventions, tempMyInterventions],
            all_interventions: [
              ...prev.all_interventions,
              tempAllInterventions,
            ],
          };
        } else {
          return {
            ...prev,
            all_interventions: [
              ...prev.all_interventions,
              tempAllInterventions,
            ],
          };
        }
      });
    } catch (error) {
      console.error("error deleting all interventions: ", error);
    }
  }

  async function deleteIntervention() {
    try {
      await fetch(
        `${process.env.REACT_APP_API_URL}intervention/delete/${event.id}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + state.currentUser.token,
          },
        }
      );

      setCurrMonthInterventions((prev) => {
        const role = state.currentUser?.user?.role;
        const temp = { ...prev };
        if (role === 2) {
          const tempNewInterventions = [...temp.my_interventions];
          const tempAllInterventions = [...temp.all_interventions];
          console.log(tempNewInterventions);
          tempNewInterventions.splice(
            temp.my_interventions.findIndex(
              (intervention) => intervention.id === event.id
            ),
            1
          );

          tempAllInterventions.splice(
            temp.all_interventions.findIndex(
              (intervention) => intervention.id === event.id
            ),
            1
          );

          return {
            ...temp,
            my_interventions: [...tempNewInterventions],
            all_interventions: [...tempAllInterventions],
          };
        } else {
          const tempNewInterventions = [...temp.all_interventions];
          console.log(tempNewInterventions);
          tempNewInterventions.splice(
            temp.all_interventions.findIndex(
              (intervention) => intervention.id === event.id
            ),
            1
          );
          return { ...temp, all_interventions: [...tempNewInterventions] };
        }
      });
    } catch (error) {
      console.error("Error deleting intervention: ", error);
    }
  }

  async function attendMeeting(eventId) {
    try {
      const data = await fetch(
        `${process.env.REACT_APP_API_URL}intervention/attend/${eventId}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + state.currentUser.token,
          },
          body: JSON.stringify({
            date: event.date,
          }),
        }
      );
      const res = await data.json();
      if (res.result) {
        setShowModal(false);
        window.location.reload();
      } else if (res.error) {
        setError(res.error);
      }
    } catch (error) {
      console.error(error);
      setError(`There was an error attending this intervention: \n ${error}`);
    }
  }

  function datesAreOnTheSameDay(date1, date2) {
    if (!(date1 instanceof Date) || !(date2 instanceof Date)) return false;

    return !!(
      date1.getYear() === date2.getYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  async function cancelAttendance(eventId) {
    try {
      const data = await fetch(
        `${process.env.REACT_APP_API_URL}intervention/cancel/${eventId}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + state.currentUser.token,
          },
        }
      );
      const res = await data.json();
      if (res.result) {
        setShowModal(false);
        window.location.reload();
      } else if (res.error) {
        setError(res.error);
      }
    } catch (error) {
      console.error("There was an error cancelling attendance: " + error);
    }
  }

  function isTeacher() {
    if (
      state.currentUser?.user?.role === 2 ||
      state.currentUser?.user?.role === 4
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div>
      <div className="flex items-center gap-1 ml-4">
        <div
          className="rounded-full w-2 h-2"
          style={{ backgroundColor: setBgColor(event) }}
        ></div>
        <button className={`event-wrapper truncate`} onClick={showPopup}>
          {`${event.owner_name ? `${event.owner_name}` : ""} - `}
          {event?.title}
        </button>
      </div>
      {showConfirmDelete && (
        <CenteredModal
          className="sm:w-fit"
          smallModal={true}
          onShowModal={() => setShowConfirmDelete(false)}
          callback={() => onSelect(event)}
        >
          <ConfirmDeleteEvent
            setShowConfirmDelete={setShowConfirmDelete}
            setShowModal={setShowModal}
            currDate={event?.date}
            isRepeatingEvent={event?.intervention_series_id ? true : false}
            deleteOne={() => {
              deleteIntervention(event.id);
              setShowConfirmDelete(false);
            }}
            setDeleteAll={() => {
              deleteSubsequentInterventions();
            }}
            cancelBtnCallback={() => {
              setShowModal(true);
              setShowConfirmDelete(false);
            }}
          />
        </CenteredModal>
      )}
      {showModal && (
        <CenteredModal
          callback={() => onSelect(event)}
          lightBg={true}
          smallModal={true}
          className="shadow-2xl rounded-md border border-gray-100 flex flex-col gap-2 pl-10 pr-6 py-5 max-w-md"
          onShowModal={() => {
            setShowModal(false);
            setError(false);
            setFilteredAttendees(intervention?.attendees);
          }}
        >
          <div className="flex gap-8 self-end text-gray-600">
            {/* If this intervention is owned by the user
                OR they are a teacher where teacherEditing = true
                AND the user's school does not restrict teacher activity during restricted time
                AND it is not currently within restricted times,
                OR they are an admin
            */}
            {(state.currentUser?.user?.role === 1 ||
              (event.owner === state.currentUser?.user?.id &&
                !isRestrictedTime) ||
              (isTeacher() && teacherEditing && !isRestrictedTime)) && (
              <EditSVG
                className={
                  loading || error ? "text-gray-300" : "cursor-pointer"
                }
                onClick={() => {
                  if (!loading) {
                    if (isCopiedIntervention) {
                      setIsCopiedIntervention(false);
                    }
                    setShowEditModal(true);
                  }
                }}
              />
            )}
            {(state.currentUser?.user?.role === 1 ||
              (event.owner === state.currentUser?.user?.id &&
                !isRestrictedTime) ||
              (isTeacher() && teacherEditing && !isRestrictedTime)) && (
              <CopySVG
                className={
                  loading || error ? "text-gray-300" : "cursor-pointer"
                }
                onClick={() => {
                  if (!loading) {
                    setCopiedInterventionDetails({
                      ...intervention,
                      copied: true,
                    });
                    setIsCopiedInterventionWrapper(true);
                    setShowInterventionModal(true);
                  }
                }}
              />
            )}
            {((event.owner === state.currentUser?.user?.id &&
              !isRestrictedTime) ||
              (adminDelete && state.currentUser?.user?.role === 1)) && (
              <TrashSVG
                className={
                  loading || error ? "text-gray-300" : "cursor-pointer"
                }
                onClick={() => {
                  if (!loading) {
                    setShowConfirmDelete(true);
                    setShowModal(false);
                  }
                }}
              />
            )}
            <CloseSVG
              className="cursor-pointer"
              onClick={() => setShowModal(false)}
            />
          </div>

          <h2 className="text-3xl font-normal break-words">
            {event?.owner_name} - {event?.title}
          </h2>
          <p className="text-gray-700 mb-6">
            {new Date(event.start).toDateString()}
          </p>
          {event.time && <p>{event.time}</p>}
          {loading ? (
            <div className="m-auto">
              <p className="mb-1 text-blueprimary-600 text-2xl">Loading...</p>
              <BarLoader color="#4383cb" />
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              {state.currentUser?.user?.role !== 3 && intervention?.private && (
                <div className="flex items-center gap-2">
                  <p className=" text-base font-semibold text-gray-500">
                    Private Intervention
                  </p>
                  <LockSVG className="text-gray-500 w-6" />
                </div>
              )}
              <p className=" text-lg">Room: {intervention?.room}</p>
              {state.currentUser?.user?.role !== 3 ? (
                <p className=" text-lg">
                  Intervention Capacity: {intervention?.attendees?.length}/
                  {intervention?.capacity}
                </p>
              ) : (
                <p className=" text-lg">
                  Seats Remaining: {intervention?.capacity_remaining}/
                  {intervention?.capacity}
                </p>
              )}

              {/* {state.currentUser?.user?.role === 3 && <p>Seats Remaining: {intervention?.capacity_remaining}</p>} */}

              {error && <span className="text-red-500 block">{error}</span>}
              {state.currentUser?.user?.role !== 3 &&
                intervention?.attendees?.length > 0 && (
                  <EventAttendeesList
                    currentUser={state.currentUser}
                    event={event}
                    teacherEditing={teacherEditing}
                    intervention={intervention}
                    setFilteredAttendees={setFilteredAttendees}
                    filteredAttendees={filteredAttendees}
                  />
                )}
            </div>
          )}

          {state.currentUser?.user?.role === 3 &&
            (!isWithinRestrictedTimes() ? (
              <div className="flex gap-2">
                {event.color === "blue" && (
                  <button
                    className="bg-blueprimary-500 text-white px-2 py-1"
                    onClick={() => attendMeeting(event.id)}
                  >
                    Attend
                  </button>
                )}
                {event.color === "yellow" && (
                  <button
                    className="bg-red-500 text-white px-2 py-1"
                    onClick={() => cancelAttendance(event.id)}
                  >
                    Cancel Attendance
                  </button>
                )}
              </div>
            ) : (
              <RestrictedTimesNotice
                dayOfTheWeek={dayOfTheWeek}
                restrictedTimes={restrictedTimes}
              />
            ))}
        </CenteredModal>
      )}
      {showEditModal && (
        <CenteredModal
          callback={() => onSelect(event)}
          onShowModal={() => setShowEditModal(false)}
        >
          <EditIntervention
            isCopiedIntervention={isCopiedIntervention}
            setIsCopiedIntervention={setIsCopiedIntervention}
            allowMultipleInterventions={allowMultipleInterventions}
            setShowEditModal={setShowEditModal}
            event={intervention}
            setInterventions={setCurrMonthInterventions}
          />
        </CenteredModal>
      )}
    </div>
  );
}
