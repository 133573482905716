import React from "react";
import Button from "../Button";

const HomeFooter = () => {
  return (
    <footer
      id="contact-us"
      className="flex items-start flex-wrap justify-between bg-tssBlue-200 w-full px-12 lg:px-24 py-16"
    >
      <div className="flex flex-col items-start">
        <div className="flex flex-col gap-4 items-start">
          <img src="tss-nav.png" alt="tss logo" className="h-10 lg:h-auto" />
          <span className="font-medium text-tssBlack-500 text-2xl">
            Tiered Support System
          </span>
        </div>
        <div className="flex gap-8 items-center flex-wrap mt-8 mb-4 sm:mb-0">
          <div className="flex flex-col items-start">
            <span className="text-base font-normal text-tssGray-600">
              Email
            </span>
            <span className="text-xl font-light text-tssBlack-500">
              mikasalas@tieredtracker.com
            </span>
          </div>
          <div className="flex flex-col items-start">
            <span className="text-base font-normal text-tssGray-600">
              Phone
            </span>
            <span className="text-xl font-light text-tssBlack-500">
              (435) 650-6251
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start gap-4">
        <span className="text-tssBlack-500 font-medium text-2xl">
          Get started to optimize
          <br /> your calendar today
        </span>
        <div className="flex items-center gap-4">
          <Button>Login</Button>
          <a
            href="mailto:mikasalas@tieredtracker.com?subject=Tiered System Support Inquiry"
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="secondary">Contact Our Team</Button>
          </a>
        </div>
      </div>
    </footer>
  );
};
export default HomeFooter;
