import React, { useContext, useState, useEffect, useCallback } from "react";
import Button from "../Button";
import { StateContext } from "../../contexts/State";
import SelectAttendees from "./SelectAttendees";
import RepeatingInterventionForm from "./RepeatingInterventionForm";
import ImportAttendees from "./ImportAttendees";
import DatePicker, { DateObject } from "react-multi-date-picker";

const AddIntervention = ({
  restrictedDays,
  setShowInterventionModal,
  setInterventions,
  allowMultipleInterventions,
  // reference Dashboard.jsx ln. 57 to see the use of newInterventionDate
  newInterventionDate,
  isCopiedIntervention = false,
  copiedIntervention = null,
  setIsCopiedIntervention = () => {},
}) => {
  const { state, dispatch } = useContext(StateContext);
  const [interventionDate, setInterventionDate] = useState("");
  const [interventionName, setInterventionName] = useState("");
  const [interventionCapacity, setInterventionCapacity] = useState(0);
  const [interventionRoom, setInterventionRoom] = useState("");
  const [privateSession, setPrivateSession] = useState(false);
  const [students, setStudents] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [attendeeWarning, setAttendeeWarning] = useState(false);
  const [isRepeating, setIsRepeating] = useState(false);
  const [repeatingEventInfo, setRepeatingEventInfo] = useState({
    repeat_content: "details",
    repeat_type: "daily",
    repeat_until_date: "",
    repeat_until_count: 2,
    days_of_week: [],
    // endType does not get sent in request
    endType: "date",
  });
  const [showImporting, setShowImporting] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (isCopiedIntervention) {
      const tempIntervention = { ...copiedIntervention };
      setInterventionDate(tempIntervention.date);
      setInterventionName(tempIntervention.name);
      setInterventionCapacity(tempIntervention.capacity);
      setInterventionRoom(tempIntervention.room?.toString());
      setPrivateSession(tempIntervention.private);
      setAttendees(tempIntervention.attendees);
      setIsCopiedIntervention(false);
    }
  }, [copiedIntervention, isCopiedIntervention, setIsCopiedIntervention]);

  const getStudents = useCallback(async () => {
    setLoading(true);
    const tempDate =
      typeof interventionDate === "string" && interventionDate?.includes("-")
        ? interventionDate.split("-").join("/")
        : interventionDate;
    const formattedDate =
      typeof tempDate !== "string" ? tempDate.format("YYYY/MM/DD") : tempDate;
    try {
      if (document.getElementById("attendees-searchbar")) {
        document.getElementById("attendees-searchbar").value = "";
      }
      const data = await fetch(
        `${process.env.REACT_APP_API_URL}all_students/${formattedDate}`,
        {
          headers: {
            Authorization: "Bearer " + state.currentUser.token,
          },
        }
      );
      const res = await data.json();
      setStudents(res);
      setLoading(false);
      if (document.getElementById("attendees-searchbar")) {
        document.getElementById("attendees-searchbar").value = "";
      }
    } catch (error) {
      console.error("error getting students: ", error);
      setLoading(false);
    }
  }, [interventionDate, state.currentUser.token]);

  const createIntervention = async () => {
    setSaving(true);
    try {
      let attendeeIDs = [];
      if (attendees.length > 0) {
        attendeeIDs = attendees.map((attendee) => attendee.id);
      }

      let repeatBody = {
        repeat_content: repeatingEventInfo.repeat_content,
        repeat_type: repeatingEventInfo.repeat_type,
      };
      if (repeatingEventInfo.endType === "occurrence") {
        repeatBody.repeat_until_count = repeatingEventInfo.repeat_until_count;
      } else {
        repeatBody.repeat_until_date = repeatingEventInfo.repeat_until_date;
      }

      if (repeatingEventInfo.repeat_type === "custom") {
        repeatBody.days_of_week = repeatingEventInfo.days_of_week;
      }

      const data = isRepeating
        ? await fetch(
            `${process.env.REACT_APP_API_URL}intervention/add/repeating`,
            {
              method: "POST",
              headers: {
                Authorization: "Bearer " + state.currentUser.token,
              },
              body: JSON.stringify({
                date:
                  typeof interventionDate === "string"
                    ? interventionDate
                    : interventionDate.format("YYYY-MM-DD"),
                name: interventionName,
                capacity: Number(interventionCapacity),
                room: interventionRoom,
                private: privateSession,
                required_attendees: attendeeIDs,
                ...repeatBody,
              }),
            }
          )
        : await fetch(`${process.env.REACT_APP_API_URL}intervention/add`, {
            method: "POST",
            headers: {
              Authorization: "Bearer " + state.currentUser.token,
            },
            body: JSON.stringify({
              date:
                typeof interventionDate === "string"
                  ? interventionDate
                  : interventionDate.format("YYYY-MM-DD"),
              name: interventionName,
              capacity: Number(interventionCapacity),
              room: interventionRoom,
              private: privateSession,
              required_attendees: attendeeIDs,
              // required_attendees: [INT] - should be student PK
            }),
          });
      const res = await data.json();
      console.log("RES: ", res);
      setSaving(false);
      return res;
    } catch (error) {
      console.error("error creating intervention: ", error);
      setSaving(false);
    }
  };

  const formatIntervention = (newIntervention) => {
    return {
      ...newIntervention,
      // color: state.currentUser?.user?.role === 2 ? "green" : "blue",
      color: "blue",
      start: new Date(`${newIntervention.date}T10:00:00`).toUTCString(),
      end: new Date(`${newIntervention.date}T10:00:00`).toUTCString(),
      title: `${newIntervention.name}`,
    };
  };

  const submitIntervention = async (e) => {
    e.preventDefault();
    // TODO: Provide warning if user does not select days of the week
    if (interventionDate === "") {
      console.log("error");
      setError("Please select a start date for your intervention.");
      return;
    }
    if (interventionRoom === "" || !interventionRoom) {
      setError("Please select a room for your intervention.");
      return;
    }
    if (isRepeating) {
      if (
        repeatingEventInfo.repeat_type === "custom" &&
        repeatingEventInfo.days_of_week.length === 0
      ) {
        console.log("error");
        setError(
          "Please select days of the week for your intervention to repeat on."
        );
        return;
      }
      if (
        repeatingEventInfo.endType === "date" &&
        repeatingEventInfo.repeat_until_date === ""
      ) {
        console.log("error");
        setError("Please select a day for your intervention to end on.");
        return;
      }
    }
    const newIntervention = await createIntervention();
    console.log("new intervention: ", newIntervention);
    setInterventions((prev) => {
      console.log("PREV: ", prev);
      const formattedIntervention = formatIntervention(newIntervention);
      const formattedRepInterventions = [];
      if (isRepeating) {
        for (let i = 0; i < newIntervention.length; i++) {
          formattedRepInterventions.push(
            formatIntervention(newIntervention[i])
          );
        }
      }
      if (state.currentUser?.user?.role === 2) {
        if (isRepeating) {
          return {
            ...prev,
            my_interventions: [
              ...prev.my_interventions,
              ...formattedRepInterventions,
            ],
            all_interventions: [
              ...prev.all_interventions,
              ...formattedRepInterventions,
            ],
          };
        } else {
          return {
            ...prev,
            my_interventions: [...prev.my_interventions, formattedIntervention],
            all_interventions: [
              ...prev.all_interventions,
              formattedIntervention,
            ],
          };
        }
      } else {
        if (isRepeating) {
          return {
            ...prev,
            all_interventions: [
              ...prev.all_interventions,
              ...formattedRepInterventions,
            ],
          };
        } else {
          return {
            ...prev,
            all_interventions: [
              ...prev.all_interventions,
              formattedIntervention,
            ],
          };
        }
      }
    });
    setShowInterventionModal(false);
  };

  function CustomInput({ openCalendar, value, handleValueChange }) {
    let formatDate = "";
    if (value.length > 0) {
      var y = value.toString().substr(0, 4),
        m = value.toString().substr(5, 2),
        d = value.toString().substr(8, 2);
      formatDate = `${m}/${d}/${y}`;
    }
    return (
      <input
        name="date"
        placeholder="mm/dd/yyyy"
        onFocus={openCalendar}
        required
        readOnly
        value={formatDate}
        className=" border-gray-300 border rounded-md px-3 py-2 shadow-sm w-full"
      />
    );
  }

  function DateMapper(date) {
    if (restrictedDays.includes(date.format("YYYY-MM-DD"))) {
      return {
        disabled: true,
        style: { color: "#ccc" },
      };
    }
  }

  useEffect(() => {
    if (
      (state.currentUser?.user?.role === 1 ||
        state.currentUser?.user?.role === 2) &&
      interventionDate !== ""
    ) {
      getStudents();
    }

    return () => {};
  }, [interventionDate, getStudents, state.currentUser?.user?.role]);

  useEffect(() => {
    if (attendees?.length > 0) {
      // filter through the list of students and find current attendees,
      // then check to see if the filtered students have an attendance for the new intervention date
      // let findAttendances = [];
      // for (let i = 0; i < attendees.length; i++) {
      //   findAttendances = students.filter(
      //     (student) => student?.id === attendees[i]?.id
      //   );
      // }

      const checkForAttendance = students.find(
        (student) => student?.attendance
      );
      setAttendeeWarning(!!checkForAttendance);
    } else {
      setAttendeeWarning(false);
    }
  }, [attendees, students]);

  useEffect(() => {
    if (attendees?.length > 0) {
      if (attendees.length > interventionCapacity) {
        setError(
          "You have more attendees than your capacity allows for this intervention. \n Please edit your intervention capacity, or adjust your attendees."
        );
      } else {
        setError("");
      }
    }
  }, [attendees?.length, interventionCapacity]);

  useEffect(() => {
    if (newInterventionDate !== "" && newInterventionDate) {
      setInterventionDate(newInterventionDate);
    }
  }, [newInterventionDate]);

  return showImporting ? (
    <ImportAttendees
      setAttendees={setAttendees}
      setCapacity={setInterventionCapacity}
      goBack={() => {
        setShowImporting(false);
      }}
    />
  ) : (
    <form
      className="flex flex-col items-start gap-4 p-4"
      onSubmit={async (e) => await submitIntervention(e)}
    >
      {/* <h2 className="text-center self-center text-3xl">New Intervention</h2> */}
      <div className="flex justify-between gap-4 w-full">
        <div>
          <label
            htmlFor="date"
            className="block text-xl font-medium text-gray-700 mb-2"
          >
            {isRepeating ? "Start Date" : "Date"}
          </label>
          <DatePicker
            format="YYYY-MM-DD"
            onChange={(dateObject) => {
              setInterventionDate(dateObject);
              setError("");
            }}
            value={interventionDate}
            name="date"
            required
            render={<CustomInput />}
            className="border-gray-300 border rounded-md ml-10 px-3 py-2 shadow-sm w-full form-control"
            style={{ borderWidth: "1px", borderColor: "rgb(209 213 219 / 1)" }}
            calendarPosition={"bottom-right"}
            offsetY={10}
            mapDays={({ date }) => {
              return DateMapper(date);
            }}
          />
        </div>
        <fieldset
          className="text-right"
          onChange={(e) => {
            if (e.target.value === "yes") {
              setIsRepeating(true);
            } else {
              setIsRepeating(false);
            }
          }}
        >
          <legend className="block text-xl font-medium text-gray-700 mb-2">
            Repeating intervention?
          </legend>
          <div className="flex gap-2 items-center justify-end">
            <label className="text-base" htmlFor="yes">
              Yes
            </label>
            <input
              type="radio"
              id="yes"
              value="yes"
              name="repeating"
              defaultChecked={isRepeating}
            />
            <label className="text-base" htmlFor="no">
              No
            </label>
            <input
              type="radio"
              id="no"
              value="no"
              defaultChecked={!isRepeating}
              name="repeating"
            />
          </div>
        </fieldset>
      </div>
      <fieldset
        onChange={(e) => {
          if (e.target.value === "yes") {
            setPrivateSession(true);
          } else {
            setPrivateSession(false);
          }
        }}
      >
        <legend className="block text-xl font-medium text-gray-700 ">
          Private intervention?
        </legend>
        <span className="text-sm mb-2 text-gray-500">
          Private interventions will prevent students from being able to
          self-assign themselves to an intervention.
        </span>
        <div className="flex gap-2 items-center">
          <label className="text-base" htmlFor="yes">
            Yes
          </label>
          <input
            type="radio"
            id="yes"
            value="yes"
            name="private-intervention"
            defaultChecked={privateSession}
          />
          <label className="text-base" htmlFor="no">
            No
          </label>
          <input
            type="radio"
            id="no"
            value="no"
            defaultChecked={!privateSession}
            name="private-intervention"
          />
        </div>
      </fieldset>
      {isRepeating && (
        <RepeatingInterventionForm
          CustomInput={CustomInput}
          DateMapper={DateMapper}
          restrictedDays={restrictedDays}
          interventionDate={interventionDate}
          setRepeatingEventInfo={setRepeatingEventInfo}
          repeatingEventInfo={repeatingEventInfo}
          setError={setError}
          error={error}
        />
      )}
      <div className="w-full">
        <label
          htmlFor="name"
          className="block text-xl font-medium text-gray-700 mb-2"
        >
          Title of Intervention
        </label>
        <input
          name="name"
          type="text"
          required
          className=" border-gray-300 border rounded-md px-3 py-2 shadow-sm w-full"
          defaultValue={interventionName}
          onChange={(e) => {
            setInterventionName(e.target.value);
          }}
        />
      </div>
      <div className="flex justify-between gap-4 w-full">
        <div>
          <label
            htmlFor="capacity"
            className="block text-xl font-md text-gray-700 mb-2"
          >
            Capacity
          </label>
          <input
            name="capacity"
            type="number"
            value={interventionCapacity}
            className=" border-gray-300 border rounded-md px-3 py-2 shadow-sm w-full"
            onChange={(e) => {
              setInterventionCapacity(e.target.value);
            }}
          />
        </div>
        <div>
          <label
            htmlFor="room"
            className="block text-xl font-medium text-gray-700 mb-2"
          >
            Room
          </label>
          <input
            required
            name="room"
            type="text"
            defaultValue={interventionRoom}
            className=" border-gray-300 border rounded-md px-3 py-2 shadow-sm w-full"
            onChange={(e) => {
              setInterventionRoom(e.target.value);
            }}
          />
        </div>
      </div>
      {error && <p className="text-red-500 text-sm">{error}</p>}
      {(!isRepeating ||
        (isRepeating && repeatingEventInfo["repeat_content"] === "all")) && (
        <>
          <button
            disabled={interventionDate === "" ? true : false}
            className="flex items-center gap-2 shadow-md mt-2 px-2 py-1 rounded-md bg-greenprimary-500 text-white text-base hover:bg-greenprimary-600 disabled:bg-gray-300"
            onClick={() => {
              setShowImporting(true);
            }}
          >
            Import attendees
          </button>

          <SelectAttendees
            students={students}
            setStudents={setStudents}
            setAttendees={setAttendees}
            attendees={attendees}
            capacity={interventionCapacity}
            interventionDate={interventionDate}
            loading={loading}
          />
        </>
      )}

      {attendeeWarning && !allowMultipleInterventions && (
        <div className="mt-2 text-base text-red-500">
          <span>The following student(s): </span>
          {attendees
            .filter((attendee) => attendee?.attendance)
            .sort((a, b) => {
              if (a.last_name.toLowerCase() > b.last_name.toLowerCase())
                return 1;
              if (a.last_name.toLowerCase() < b.last_name.toLowerCase())
                return -1;
              return 0;
            })
            .map((attendee, key, prevArr) => (
              <span key={key}>
                {attendee.last_name}, {attendee.first_name}{" "}
                {key !== prevArr.length - 1 && "-"}{" "}
              </span>
            ))}
          <span className="block sm:w-1/2 mt-2">
            Are assigned to another intervention. Assigning them to this
            intervention will remove them from their previous intervention.
          </span>
        </div>
      )}
      {copiedIntervention.copied ? (
        <Button
          className="disabled:bg-gray-400 bg-greenprimary-500 shadow-md rounded-lg mt-4 self-end px-10"
          type="submit"
          disabled={!!error}
        >
          {saving ? "Saving..." : "Save as New Intervention"}
        </Button>
      ) : (
        <Button
          className="disabled:bg-gray-400 bg-blueprimary-500 shadow-md rounded-lg mt-4 self-end"
          type="submit"
          disabled={!!error}
        >
          {saving ? "Creating..." : "Create Intervention"}
        </Button>
      )}
    </form>
  );
};
export default AddIntervention;
