import React, { useEffect, useCallback, useState } from "react";
import Button from "../Button";

const ManageTeacherEditing = ({ currentUser = {}, ...props }) => {
  let [teacherEditing, setTeacherEditing] = useState(false);
  let [changeMade, setchangeMade] = useState(false);

  const getTeacherEditing = useCallback(async () => {
    if (currentUser?.token) {
      try {
        const data = await fetch(
          `${process.env.REACT_APP_API_URL}school/school-settings`,
          {
            headers: {
              Authorization: "Bearer " + currentUser?.token,
            },
          }
        );
        const res = await data.json();
        if (!res.error) {
          setTeacherEditing(res.allow_teacher_edit);
        }
      } catch (error) {
        console.error("error getting signup times: ", error);
      }
    }
  }, [currentUser?.token]);

  useEffect(() => {
    getTeacherEditing();
  }, [getTeacherEditing]);

  async function saveTeacherEditing(value) {
    if (!currentUser?.token) return;
    try {
      const data = await fetch(
        `${process.env.REACT_APP_API_URL}school/teacher-edit/${currentUser?.user?.school}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + currentUser?.token,
          },
          body: JSON.stringify({
            allow_teacher_edit: value,
          }),
        }
      );
      const res = await data.json();
      setchangeMade(false);
    } catch (error) {
      console.error("error getting signup times: ", error);
    }
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        saveTeacherEditing(teacherEditing);
      }}
      className="flex flex-row items-center gap-2 p-2 my-4 border border-gray-300 rounded-md w-full shadow-md"
      {...props}
    >
      <label className="font-semibold">
        Allow only admins to edit all interventions{" "}
        <input
          id="admin-allow"
          name="edit-permission"
          className="w-5 h-5 ml-2 align-text-bottom"
          type="checkbox"
          checked={!teacherEditing}
          onChange={(e) => {
            setTeacherEditing(!teacherEditing);
            setchangeMade(true);
          }}
        />
      </label>
      <label className="font-semibold">
        Allow teachers to edit all interventions{" "}
        <input
          id="teacher-allow"
          name="edit-permission"
          className="w-5 h-5 ml-2 align-text-bottom"
          type="checkbox"
          checked={teacherEditing}
          onChange={(e) => {
            setTeacherEditing(!teacherEditing);
            setchangeMade(true);
          }}
        />
      </label>
      <button
        disabled={!changeMade}
        type="submit"
        className="rounded-md shadow-md text-white px-2 py-1 disabled:bg-gray-300 bg-blueprimary-500 hover:bg-blueprimary-600"
      >
        Save
      </button>
    </form>
  );
};
export default ManageTeacherEditing;
