import React, { useState, useContext, useEffect } from "react";
import { StateContext } from "../contexts/State";
import { useDeviceDetect } from "../services/useDeviceDetect";
import Navbar from "../components/layouts/Navbar";
import Hamburger from "../components/svg/Hamburger";
import UsersList from "../components/admin/UsersList";
import ManageTeacherEditing from "../components/admin/ManageTeacherEditing";
import ManageInterventionSetting from "../components/admin/ManageInterventionSetting";
import ManageAdminDeleting from '../components/admin/ManageAdminDeleting';
import ManageInterventionSignup from "../components/admin/ManageInterventionSignup";

const Admin = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const { state } = useContext(StateContext);

  

  return (
    <section>
      <Navbar open={showNavbar} />
      <div
        className="dashboard-content h-screen"
        onClick={() => {
          if (showNavbar) setShowNavbar(false);
        }}
      >
        <Hamburger
          className="hamburger-icon px-4 py-4 h-10 w-10"
          onClick={() => {
            setShowNavbar(true);
          }}
        />
        <h1 className="text-2xl">Add/Edit Users</h1>
        <div className="flex items-center gap-2 mt-4">
          {/* <button className="text-sm bg-greenprimary-500 rounded-md shadow-md px-2 py-1 text-white hover:bg-greenprimary-600">
            Run Reports
          </button> */}
          {/* <button className="text-sm bg-blueprimary-500 rounded-md shadow-md px-2 py-1 text-white hover:bg-blueprimary-600">
            Manage Intervention Calendar
          </button> */}
        </div>
        {/* <ManageTimes currentUser={state?.currentUser} />
        <RestrictedDays currentUser={state?.currentUser}/> */}
        <ManageAdminDeleting currentUser={state?.currentUser} />
        <ManageTeacherEditing currentUser={state?.currentUser} />
        <ManageInterventionSetting currentUser={state?.currentUser} />
        <ManageInterventionSignup currentUser={state?.currentUser} />
        <UsersList />
      </div>
    </section>
  );
};
export default Admin;
