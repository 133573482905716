import React from "react";
import { isWithinRestrictedTimes } from "../../services/helpers";

/**
 * Allows Teachers & Admins to toggle between "My Interventions" and "All Interventions".
 */
const CalendarFilter = ({
  currentUser = {},
  interventionFilter = "",
  setInterventionFilter = () => {},
  setShowInterventionModal = () => {},
  restrictedTimes = [],
  restrictTeacherAccess = false,
}) => {
  const isRestrictedTime =
    isWithinRestrictedTimes(restrictedTimes) &&
    restrictTeacherAccess &&
    (currentUser?.user?.role === 2 || currentUser?.user?.role === 4);

  return (
    <div className="flex gap-4 items-center my-4">
      <select
        defaultValue={
          currentUser?.user?.role === 2
            ? "my_interventions"
            : interventionFilter
        }
        onChange={(e) => setInterventionFilter(e.target.value)}
        className="select-arrow text-tssGray-600 font-light text-sm px-4 py-2 rounded-3xl border-[1.5px] border-tssGray-500"
      >
        {currentUser?.user?.role !== 1 && (
          <option value="my_interventions">My Interventions</option>
        )}
        <option value="all_interventions">All Interventions</option>
      </select>
      <button
        disabled={isRestrictedTime}
        className=" bg-tssBlue-500 font-light text-sm rounded-3xl px-4 py-2 text-white hover:bg-blueprimary-600 disabled:bg-gray-400"
        onClick={() => {
          setShowInterventionModal(true);
        }}
      >
        Add Intervention
      </button>
    </div>
  );
};
export default CalendarFilter;
