import React, { useContext, useState, useCallback, useRef } from "react";
import Button from ".././Button";
import { StateContext } from "../../contexts/State";
import { useEffect } from "react";
import SelectAttendees from "./SelectAttendees";

const EditIntervention = ({
  setShowEditModal,
  hideTitle,
  setInterventions,
  event,
  className,
  callback = () => {},
  allowMultipleInterventions,
  isCopiedIntervention = false,
  setIsCopiedIntervention = () => {},
}) => {
  const { state, dispatch } = useContext(StateContext);
  const [students, setStudents] = useState([]);
  const [attendees, setAttendees] = useState([...event.attendees]);
  const [currentAttendees] = useState([...event.attendees]);
  const [capacity, setCapacity] = useState(event.capacity);
  const [name, setName] = useState(event.name);
  const [date, setDate] = useState(event.date);
  const [room, setRoom] = useState(event.room);
  const [privateSession, setPrivateSession] = useState(event.private);
  const [attendeeWarning, setAttendeeWarning] = useState(false);
  const [editAllInstances, setEditAllInstances] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saveAsNew, setSaveAsNew] = useState(false);

  // For repeating interventions only
  const [updateRoster, setUpdateRoster] = useState(true);
  const dateRef = useRef(null);

  useEffect(() => {
    if (isCopiedIntervention) {
      setDate("");
    }
  }, [isCopiedIntervention, setIsCopiedIntervention]);

  const getStudents = useCallback(async () => {
    setLoading(true);
    const formattedDate = date.split("-").join("/");

    try {
      if (document.getElementById("attendees-searchbar")) {
        document.getElementById("attendees-searchbar").value = "";
      }
      const data = await fetch(
        `${process.env.REACT_APP_API_URL}all_students/${formattedDate}`,
        {
          headers: {
            Authorization: "Bearer " + state.currentUser.token,
          },
        }
      );
      const res = await data.json();
      console.log(res);
      setStudents(res);
      setLoading(false);
      if (document.getElementById("attendees-searchbar")) {
        document.getElementById("attendees-searchbar").value = "";
      }
    } catch (error) {
      console.error("error getting students: ", error);
    }
  }, [date, state.currentUser.token]);

  const editEvent = async () => {
    try {
      let attendeeIDs = [];
      if (attendees.length > 0) {
        attendeeIDs = attendees.map((attendee) => attendee.id);
      }

      // EDIT SUBSEQUENT INSTANCES OF REPEATING INTERVENTION
      let body = {};
      if (updateRoster) {
        body = JSON.stringify({
          date: event.date,
          name: name,
          capacity: Number(capacity),
          room: room,
          private: privateSession,
          required_attendees: attendeeIDs,
          update_roster: updateRoster,
        });
      } else {
        body = JSON.stringify({
          date: event.date,
          name: name,
          room: room,
          private: privateSession,
          required_attendees: attendeeIDs,
        });
      }
      const data = editAllInstances
        ? await fetch(
            `${process.env.REACT_APP_API_URL}intervention/edit/repeating/${event?.intervention_series_id}`,
            {
              method: "POST",
              headers: {
                Authorization: "Bearer " + state.currentUser.token,
              },
              body: body,
            }
          )
        : // EDIT ONE INTERVENTION
          await fetch(
            `${process.env.REACT_APP_API_URL}intervention/edit/${event.id}`,
            {
              method: "POST",
              headers: {
                Authorization: "Bearer " + state.currentUser.token,
              },
              body: JSON.stringify({
                date: date,
                name: name,
                capacity: Number(capacity),
                room: room,
                private: privateSession,
                required_attendees: attendeeIDs,
              }),
            }
          );
      const res = await data.json();
      console.log("RES: ", res);
      return res;
    } catch (error) {
      console.error("error creating intervention: ", error);
      setError(
        "There was an error editing your intervention. Please refresh the page and try again."
      );
    }
  };

  const formatIntervention = (newIntervention) => {
    return {
      ...newIntervention,
      // color: state.currentUser?.user?.role === 2 ? "green" : "blue",
      color: "blue",
      start: new Date(`${newIntervention.date}T10:00:00`).toUTCString(),
      end: new Date(`${newIntervention.date}T10:00:00`).toUTCString(),
      title: `${newIntervention.name}`,
    };
  };

  const submitIntervention = async (e) => {
    setSaving(true);
    e.preventDefault();
    if (!editAllInstances && (date === "" || !date)) {
      console.log("invalid");
      dateRef.current.focus();
      setError("Please select a date.");
      setSaving(false);
      return;
    }
    if (saveAsNew) {
      console.log("saving as new intervention...");
      return await submitAsNewIntervention();
    }
    const newIntervention = await editEvent();
    if (editAllInstances) {
      await setInterventions((prev) => {
        const formattedInterventions = [];
        for (let i = 0; i < newIntervention.length; i++) {
          formattedInterventions.push(formatIntervention(newIntervention[i]));
        }
        const temp = { ...prev };
        const tempAllInterventions = temp.all_interventions;
        if (state.currentUser?.user?.role === 2) {
          const tempMyInterventions = temp.my_interventions;
          for (let i = 0; i < formattedInterventions.length; i++) {
            tempMyInterventions.splice(
              temp.my_interventions.findIndex(
                (intervention) =>
                  intervention.id === formattedInterventions[i].id
              ),
              1,
              formattedInterventions[i]
            );
            tempAllInterventions.splice(
              temp.all_interventions.findIndex(
                (intervention) =>
                  intervention.id === formattedInterventions[i].id
              ),
              1,
              formattedInterventions[i]
            );
          }

          return {
            ...prev,
            my_interventions: [...prev.my_interventions, tempMyInterventions],
            all_interventions: [
              ...prev.all_interventions,
              tempAllInterventions,
            ],
          };
        } else {
          for (let i = 0; i < formattedInterventions.length; i++) {
            tempAllInterventions.splice(
              temp.all_interventions.findIndex(
                (intervention) =>
                  intervention.id === formattedInterventions[i].id
              ),
              1,
              formattedInterventions[i]
            );
          }

          return {
            ...prev,
            all_interventions: [
              ...prev.all_interventions,
              tempAllInterventions,
            ],
          };
        }
      });
    } else {
      await setInterventions((prev) => {
        const formattedIntervention = formatIntervention(newIntervention);
        if (state.currentUser?.user?.role === 2) {
          const temp = { ...prev };

          const tempMyInterventions = temp.my_interventions;
          const tempAllInterventions = temp.all_interventions;
          const myInterventionsToEdit = [
            ...tempMyInterventions.splice(
              temp.my_interventions.findIndex(
                (intervention) => intervention.id === event.id
              ),
              1,
              formattedIntervention
            ),
          ];
          const allInterventionsToEdit = [
            ...tempAllInterventions.splice(
              temp.all_interventions.findIndex(
                (intervention) => intervention.id === event.id
              ),
              1,
              formattedIntervention
            ),
          ];
          return {
            ...prev,
            my_interventions: [...prev.my_interventions, myInterventionsToEdit],
            all_interventions: [
              ...prev.all_interventions,
              allInterventionsToEdit,
            ],
          };
        } else {
          const temp = { ...prev };
          const tempAllInterventions = temp.all_interventions;
          const allInterventionsToEdit = [
            ...tempAllInterventions.splice(
              temp.all_interventions.findIndex(
                (intervention) => intervention.id === event.id
              ),
              1,
              formattedIntervention
            ),
          ];

          return {
            ...prev,
            all_interventions: [
              ...prev.all_interventions,
              allInterventionsToEdit,
            ],
          };
        }
      });
    }
    setSaving(false);
    callback(newIntervention);
    // setShowEditModal(false);
  };

  const submitAsNewIntervention = async (e) => {
    let attendeeIDs = [];
    if (attendees.length > 0) {
      attendeeIDs = attendees.map((attendee) => attendee.id);
    }
    const data = await fetch(
      `${process.env.REACT_APP_API_URL}intervention/add`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + state.currentUser.token,
        },
        body: JSON.stringify({
          date: date,
          name: name,
          capacity: Number(capacity),
          room: room,
          private: privateSession,
          required_attendees: attendeeIDs,
          // required_attendees: [INT] - should be student PK
        }),
      }
    );
    const newIntervention = await data.json();
    console.log("new intervention: ", newIntervention);
    setInterventions((prev) => {
      console.log("PREV: ", prev);
      const formattedIntervention = {
        ...newIntervention,
        // color: state.currentUser?.user?.role === 2 ? "green" : "blue",
        color: "blue",
        start: new Date(`${newIntervention.date}T10:00:00`).toUTCString(),
        end: new Date(`${newIntervention.date}T10:00:00`).toUTCString(),
        title: `${newIntervention.name}`,
      };
      if (state.currentUser?.user?.role === 2) {
        const final = {
          ...prev,
          my_interventions: [...prev.my_interventions, formattedIntervention],
          all_interventions: [...prev.all_interventions, formattedIntervention],
        };
        return final;
      } else {
        return {
          ...prev,
          all_interventions: [...prev.all_interventions, formattedIntervention],
        };
      }
    });
    setSaving(false);
    callback(newIntervention);
  };

  useEffect(() => {
    if (
      (state.currentUser?.user?.role === 1 ||
        state.currentUser?.user?.role === 2) &&
      date !== ""
    ) {
      getStudents();
    }

    return () => {};
  }, [date, getStudents, state.currentUser?.user?.role]);

  // dictates whether the attendeeWarning should display
  // !! gets the truthy/falsy of a value. !!! is getting the opposite of that value.
  useEffect(() => {
    if (attendees.length > 0) {
      // filter through the list of students and find current attendees,
      // then check to see if the filtered students have an attendance for the intervention date
      // let findAttendances = [];
      // for (let i = 0; i < attendees.length; i++) {
      //   findAttendances = students.filter(
      //     (student) => student?.id === attendees[i]?.id
      //   );
      // }
      const checkForAttendance = attendees.find(
        (student) => student?.attendance
      );
      // if we find an attendee that is assigned to an intervention, look through currentAttendees[]
      //  to see if they are part of the intervention being edited
      if (!!checkForAttendance) {
        const compareToCurrentAttendees = currentAttendees.find(
          (attendee) => attendee.id === checkForAttendance.id
        );
        // if they exist in currentAttendees[], don't show warning
        setAttendeeWarning(!!!compareToCurrentAttendees);
      } else {
        setAttendeeWarning(!!checkForAttendance);
      }
    } else {
      setAttendeeWarning(false);
    }

    return () => {};
  }, [attendees, currentAttendees, students]);

  useEffect(() => {
    if (attendees?.length > 0) {
      if (attendees.length > capacity) {
        setError(
          "You have more attendees than your capacity allows for this intervention. \n Please edit your intervention capacity, or adjust your attendees."
        );
      } else {
        setError("");
      }
    }
  }, [attendees.length, capacity]);

  return (
    <form
      className={`flex flex-col items-start gap-4 p-4 ${className}`}
      onSubmit={async (e) => await submitIntervention(e)}
    >
      {/* {!hideTitle && (
        <h2 className={`text-center self-center text-3xl`}>
          Edit Intervention
        </h2>
      )} */}
      <div className="flex justify-between gap-4 w-full">
        {!editAllInstances && (
          <div>
            <label
              htmlFor="date"
              className="block text-xl font-medium text-gray-700 mb-2"
            >
              Date
            </label>
            <input
              ref={dateRef}
              name="date"
              type="date"
              value={date}
              className=" border-gray-300 border rounded-md px-3 py-2 shadow-sm w-full"
              onChange={(e) => {
                setDate(e.target.value);
                setError("");
              }}
            />
          </div>
        )}

        {event.intervention_series_id && (
          <fieldset
            className={`${editAllInstances ? "text-left" : "text-right"}`}
            onChange={(e) => {
              if (e.target.value === "yes") {
                setEditAllInstances(true);
                setIsCopiedIntervention(false);
              } else {
                setEditAllInstances(false);
              }
            }}
          >
            <legend className="block text-xl font-medium text-gray-700 mb-2">
              Edit subsequent instances of this intervention?
            </legend>
            <div
              className={`flex gap-2 items-center ${
                editAllInstances ? "" : "justify-end"
              } `}
            >
              <label className="text-base" htmlFor="yes">
                Yes
              </label>
              <input type="radio" id="yes" value="yes" name="repeating" />
              <label className="text-base" htmlFor="no">
                No
              </label>
              <input
                type="radio"
                id="no"
                value="no"
                defaultChecked={!editAllInstances}
                name="repeating"
              />
            </div>
          </fieldset>
        )}
      </div>
      <fieldset
        onChange={(e) => {
          if (e.target.value === "yes") {
            setPrivateSession(true);
          } else {
            setPrivateSession(false);
          }
        }}
      >
        <legend className="block text-xl font-medium text-gray-700 ">
          Private intervention?
        </legend>
        <span className="text-sm mb-2 text-gray-500">
          Private interventions will prevent students from being able to
          self-assign themselves to an intervention.
        </span>
        <div className="flex gap-2 items-center">
          <label className="text-base" htmlFor="yes">
            Yes
          </label>
          <input
            type="radio"
            id="yes"
            value="yes"
            name="private-intervention"
            defaultChecked={privateSession}
          />
          <label className="text-base" htmlFor="no">
            No
          </label>
          <input
            type="radio"
            id="no"
            value="no"
            defaultChecked={!privateSession}
            name="private-intervention"
          />
        </div>
      </fieldset>
      <div className="w-full">
        <label
          htmlFor="name"
          className="block text-xl font-medium text-gray-700 mb-2"
        >
          Title of Intervention
        </label>
        <input
          name="name"
          type="text"
          className=" border-gray-300 border rounded-md px-3 py-2 shadow-sm w-full"
          defaultValue={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div className="flex justify-between gap-4 w-full">
        <div>
          <label
            htmlFor="capacity"
            className="block text-xl font-md text-gray-700 mb-2"
          >
            Capacity
          </label>
          <input
            name="capacity"
            type="number"
            className=" border-gray-300 border rounded-md px-3 py-2 shadow-sm w-full disabled:text-gray-300"
            defaultValue={capacity}
            disabled={!updateRoster}
            min={0}
            onInput={(e) => {
              if (event.intervention_series_id && editAllInstances) {
                if (Number(e.target.value) < Number(attendees.length)) {
                  setCapacity(attendees.length);
                } else {
                  setCapacity(e.target.value);
                }
              } else {
                setCapacity(e.target.value);
              }
            }}
          />
        </div>
        <div>
          <label
            htmlFor="room"
            className="block text-xl font-medium text-gray-700 mb-2"
          >
            Room
          </label>
          <input
            required
            name="room"
            type="text"
            className=" border-gray-300 border rounded-md px-3 py-2 shadow-sm w-full"
            defaultValue={room}
            onChange={(e) => {
              setRoom(e.target.value);
            }}
          />
        </div>
      </div>
      {editAllInstances && (
        <fieldset
          className={``}
          onChange={(e) => {
            if (e.target.value === "yes") {
              setUpdateRoster(true);
            } else {
              setUpdateRoster(false);
            }
          }}
        >
          <legend className="block text-xl font-medium text-gray-700 mb-2">
            Apply this roster to{" "}
            <b>this intervention and all subsequent instances</b> of this
            intervention?
          </legend>
          <div className={`flex gap-2 items-center`}>
            <label className="text-base" htmlFor="update-roster-yes">
              Yes
            </label>
            <input
              defaultChecked={updateRoster}
              type="radio"
              id="update-roster-yes"
              value="yes"
              name="update-roster"
            />
            <label className="text-base" htmlFor="update-roster-no">
              No
            </label>
            <input
              type="radio"
              id="update-roster-no"
              value="no"
              name="update-roster"
            />
          </div>
        </fieldset>
      )}
      {error && <p className="text-red-500 text-sm">{error}</p>}
      <div
        className={`${
          !updateRoster ? "filter grayscale text-gray-300" : ""
        } w-full`}
      >
        <SelectAttendees
          students={students}
          setStudents={setStudents}
          setAttendees={setAttendees}
          attendees={attendees}
          capacity={capacity}
          loading={loading}
        />
      </div>

      {attendeeWarning && !allowMultipleInterventions && (
        <div className="mt-2 text-base text-red-500">
          <span>The following student(s): </span>
          {attendees
            .filter((attendee) => attendee?.attendance)
            .filter((attendee) => !attendee?.attendance.includes(event.name))
            .sort((a, b) => {
              if (a.last_name.toLowerCase() > b.last_name.toLowerCase())
                return 1;
              if (a.last_name.toLowerCase() < b.last_name.toLowerCase())
                return -1;
              return 0;
            })
            .map((attendee, key, prevArr) => (
              <span key={key}>
                {attendee.last_name}, {attendee.first_name}{" "}
                {key !== prevArr.length - 1 && "-"}{" "}
              </span>
            ))}
          <span className="block sm:w-1/2 mt-2">
            Are assigned to another intervention. Assigning them to this
            intervention will remove them from their previous intervention.
          </span>
        </div>
      )}

      <div className="flex gap-1 justify-end self-end">
        {(isCopiedIntervention ||
          (date !== event.date && !editAllInstances)) && (
          <Button
            className="disabled:bg-gray-400 bg-greenprimary-500 shadow-md rounded-lg mt-4 self-end px-10"
            type="submit"
            onClick={() => {
              console.log("saving as new intervention");
              setSaveAsNew(true);
            }}
            disabled={!!error}
          >
            {saving ? "Saving..." : "Save as New Intervention"}
          </Button>
        )}

        {!isCopiedIntervention && (
          <Button
            className="disabled:bg-gray-400 bg-blueprimary-500 shadow-md rounded-lg mt-4 self-end px-4"
            type="submit"
            // onClick={() => submitIntervention()}
            disabled={!!error}
          >
            {saving
              ? "Saving..."
              : `${
                  updateRoster && editAllInstances
                    ? "Save Intervention & Roster"
                    : "Save Intervention"
                }`}
          </Button>
        )}
      </div>
    </form>
  );
};
export default EditIntervention;
